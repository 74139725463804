@import '../styles/variables';

.container {
  * {
    box-sizing: border-box;
  }

  display: flex;
  flex-direction: column;
  min-height: 100vh;
  position: absolute;

  main {
    display: flex;
    flex-direction: column;
    font-family: 'Roboto-Light', sans-serif;

    img {
      max-height: 100px;
    }

    .video {
      flex: 0 0 50%;
      min-width: 1%;
      position: relative;

      .mask-video {
        z-index: 0;
        position: absolute;
        width: 100%;
        height: 100%;
      }

      iframe {
        position: fixed;
        width: 100%;
        height: 100%;
        border: none;
        transform: scale(1.4);
      }
    }

    .image {
      position: relative;
      width: 100vw;
      height: 200px;
    }

    .content {
      padding-left: 15px;
      padding-right: 15px;

      background-color: white;
      z-index: 0;

      min-width: 1%;

      padding-top: 20px;

      img {
        border-radius: 50px;
        border: 4px solid black !important;
        width: 100%;
      }

      .bloc-texte {
        text-align: justify;

        h1 {
          font-weight: bold;
          text-align: center;
        }

        div {
          margin-top: 20px;
          font-weight: lighter;
        }

        @include media('>=tablet') {
          div {
            margin-top: 50px;
          }
        }
      }

      @include media('>=tablet') {
        .menu {
          padding-top: 20px;
        }
      }
    }

    @include media('>=tablet') {
      .content {
        flex: 0 0 50%;
        padding: 4vh 7vw;
      }
    }
  }

  @include media('>=tablet') {
    main {
      display: flex;
      flex: 1 0 auto;
      flex-direction: row;
    }
  }
}
