@import '../../styles/_variables.scss';

.container {
  // navigation
  nav {
    > ul > li.active {
      font-weight: bold;
    }

    a {
      color: #0f6fb9;
      text-transform: uppercase;
      text-decoration: none;
      font-size: 13px;
    }
  }

  .menu-desktop {
    display: flex;
    align-items: center;

    .preview-mode {
      -webkit-border-radius: 25px 25px 25px 25px;
      border-radius: 25px 25px 25px 25px;
      background-color: red;
      color: white;
      padding-right: 15px;
      padding-left: 15px;
      padding-top: 5px;
      padding-bottom: 5px;
      font-size: 15px;
    }

    nav {
      // 1st level
      > ul > li {
        display: inline-flex;
        padding-right: 10px;
      }

      ul {
        padding: 0;
        text-align: center;
      }
    }

    @include media('>=tablet') {
      nav {
        // 1st level
        > ul > li {
          height: 64px;
          display: inline;
          padding: 0 20px 0 20px;
        }
      }
    }
  }

  .menu-mobile {
    position: absolute;
    left: 0;
    padding: 0;
    width: 100%;
    background-color: rgba(60, 60, 60, 1);

    .preview-mode {
      width: 80%;
      position: absolute;
      top: 5px;
      margin-right: 50px;
      text-align: center;

      -webkit-border-radius: 25px 25px 25px 25px;
      border-radius: 25px 25px 25px 25px;
      background-color: red;
      color: white;
      padding-right: 15px;
      padding-left: 15px;
      padding-top: 5px;
      padding-bottom: 5px;
      font-size: 15px;
    }

    button {
      margin: 5px;
      background-color: transparent;
      border: 0;
    }

    img {
      margin: 0 auto;
      filter: invert(85%) sepia(75%) saturate(39%) hue-rotate(132deg)
        brightness(110%) contrast(110%);
    }

    // navigation
    nav {
      ul {
        padding: 0px;
        list-style-type: none;
      }

      // 1st level
      > ul > li {
        height: 45px;
        text-align: center;
      }
    }
  }
}
